import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledButtonDropdown,
} from "reactstrap";
import { NIL } from "uuid";
import Select, { components } from "react-select";
import { formatItemOptionLabel, getMaxValue } from "../../helpers/utils";
import { debounce } from "lodash";
import { getProductUomsRequest } from "../../store/product-uom/saga";
import { getPagingProductRequest } from "../../store/product/saga";
import SaleOrderProductRow from "./SaleOrderProductRow";
import moment from "moment/moment";
import { discountPromotionService } from "services/discount-promotion-service";
import ModalForm from "../sale-order-product/ModalForm";
import CreatableUserSelect from "../user/CreatableUserSelect";
import UserSelect from "../user/UserSelect";
import SaleOrderGenerateStockOutResultModal from "./SaleOrderGenerateStockOutResultModal";
import NumberFormat from "react-number-format";

const customSingleValue = ({ children, data, ...props }) => {
  const { label, uniqueId } = data;
  return (
    <components.SingleValue {...props}>
      {label} | {uniqueId}{" "}
    </components.SingleValue>
  );
};

const saleOrderTypOptions = [
  { key: 0, value: 0, label: "Default" },
  { key: 1, value: 1, label: "Facebook Page" },
  { key: 2, value: 2, label: "Walk-In" },
  { key: 3, value: 3, label: "Whole Sale" },
];

const SaleOrderForm = props => {
  const { item, onSubmit, onCancel, result } = props;

  const [term, setTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [isHeaderSelected, setIsHeaderSelected] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [loadingSender, setLoadingSender] = useState(false);
  const [senderTerm, setSenderTerm] = useState("");
  const [modalAddSaleOrderProduct, setModalAddSaleOrderProduct] =
    useState(false);
  const [discountOptions, setDiscountOptions] = useState([]);
  const [modalResult, setModalResult] = useState(false);

  const [saleOrder, setSaleOrder] = useState({
    id: NIL,
    orderNo: "",
    type: { key: 0, value: 0, label: "Draft" },
    title: "",
    customerId: "",
    sellerId: "",
    discountPromotionId: "",
    status: 0,
    saleOrderProducts: [],
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (result) {
      const { id } = result;
      setSaleOrder({
        ...saleOrder,
        id,
      });

      setModalResult(true);
    }
  }, [result]);

  const debouncedSearchSender = useCallback(
    debounce(term => {
      setSenderTerm(term);
    }, 500),
    []
  );

  useEffect(() => {
    setLoadingSender(true);
    discountPromotionService
      .getDiscountPromotionsRequest({ term: senderTerm })
      .then(records => {
        if (records) {
          setDiscountOptions(
            records.map(discountPromotion => {
              return {
                key: discountPromotion.id,
                value: discountPromotion.id,
                label: discountPromotion.name,
              };
            })
          );

          setLoadingSender(false);
        }
      });
  }, [senderTerm]);

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
    }, 500),
    []
  );

  const handleInputChange = value => {
    debouncedQuickSearch(value);
  };

  useEffect(() => {
    if (item) {
      setSaleOrder({
        ...item,
        customerId: item.customerId
          ? {
              key: item.customerId,
              value: item.customerId,
              label: item.customerName,
              uniqueId: item.customerUniqueId,
            }
          : null,
        type: {
          key: item.type,
          value: item.type,
          label: item.typeName,
        },
        sellerId: item.sellerId
          ? {
              key: item.sellerId,
              value: item.sellerId,
              label: item.sellerName,
              uniqueId: item.sellerUniqueId,
            }
          : null,
        discountPromotionId: item.discountPromotionId
          ? {
              key: item.discountPromotionId,
              value: item.discountPromotionId,
              label: item.discount,
            }
          : null,
        date: moment(item.date).toDate(),
        saleOrderProducts: item.saleOrderProducts
          ? item.saleOrderProducts.map((a, index) => {
              return {
                ...a,
                index,
                total: a.quantity * a.price,
                label: `${a.productCode} - ${a.productName}`,
                productId: {
                  key: a.productId,
                  value: a.productId,
                },
                uomId: {
                  id: a.productUomId,
                  value: a.uomId,
                  label: a.productUomTitle,
                },
              };
            })
          : [],
      });
    } else {
      setSaleOrder({
        id: NIL,
        orderNo: "",
        title: "",
        status: 0,
        type: { key: 0, value: 0, label: "Draft" },
        saleOrderProducts: [],
      });
    }
  }, [item]);

  useEffect(() => {
    setLoading(true);
    getPagingProductRequest({ term, page: 1, pageSize: 20 }).then(res => {
      if (res.data) {
        setLoading(false);
        setProductOptions(
          res.data.map((a, index) => {
            return {
              key: a.id,
              value: a.id,
              type: a.type,
              productImages: a.productImages,
              code: a.code,
              sku: a.sku,
              price: a.price,
              // itemAttachment: a.itemAttachments.length > 0 ? a.itemAttachments[0] : null,
              // quantity: a.onHandQuantity,
              label: a.name,
            };
          })
        );
      }
    });
  }, [term]);

  const handleSelectChange = (valueType, actionMeta) => {
    const { name } = actionMeta;

    switch (name) {
      case "productId":
        if (valueType) {
          const { value, label, productImages } = valueType;
          const productImage =
            productImages.length > 0 ? productImages[0] : null;
          getProductUomsRequest({ productId: value }).then(res => {
            const { data } = res;
            let uomOptions =
              data?.map(a => {
                return {
                  id: a.id,
                  value: a.uomId,
                  label: a.title,
                };
              }) ?? [];

            let max = 0;
            if (saleOrder.saleOrderProducts.length > 0) {
              max = getMaxValue(
                saleOrder.saleOrderProducts.map(a => {
                  return a.index;
                })
              );
              max += 1;
            } else {
              max = max + 1;
            }

            let newEntry = {
              index: max,
              id: NIL,
              productId: valueType,
              label: label,
              productImages: productImages,
              quantity: 1,
              price: valueType ? valueType.price : 0,
              discount: 0,
              total: 0,
              productUomId: null,
              uomId: uomOptions.length > 0 ? uomOptions[0] : null,
              uomOptions,
              isSelected: false,
            };

            setSaleOrder({
              ...saleOrder,
              saleOrderProducts: [...saleOrder.saleOrderProducts, newEntry],
            });
          });
        }
        break;
      default:
        setSaleOrder({
          ...saleOrder,
          [name]: valueType,
        });
        break;
    }
  };

  const handleDeleteSelected = () => {
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.filter(e => !e.isSelected),
    });
    setIsHeaderSelected(false);
  };

  const handleHeaderSelect = e => {
    const { checked } = e.target;
    setIsHeaderSelected(checked);
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.map(entry => {
        return {
          ...entry,
          isSelected: checked,
        };
      }),
    });
  };

  const handleStockInItemChange = entry => {
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.map(a =>
        a.index === entry.index ? entry : a
      ),
    });
  };

  const handleDeleteStockInItem = entry => {
    setSaleOrder({
      ...saleOrder,
      saleOrderProducts: saleOrder.saleOrderProducts.filter(
        e => e.index !== entry.index
      ),
    });
  };

  const handleSubmit = status => {
    setIsSubmitted(true);
    let isValid =
      saleOrder.customerId &&
      saleOrder.saleOrderProducts.length > 0;
    let hasNull = saleOrder.saleOrderProducts.some(
      e => !e.uomId || e.quantity === 0
    );

    if (!hasNull && isValid) {
      let data = {
        ...saleOrder,
        status,
        type: saleOrder.type?.value,
        customerId: saleOrder.customerId?.value,
        sellerId: saleOrder.sellerId?.value,
        discountPromotionId: saleOrder.discountPromotionId?.value,
        saleOrderProducts: saleOrder.saleOrderProducts.map(entry => {
          return {
            ...entry,
            productId: entry.productId?.value,
            productUomId: entry.uomId?.id,
            uomId: entry.uomId?.value,
          };
        }),
      };

      onSubmit(data);
    }
  };

  const handleOnSelected = product => {
    console.log(product);
  };

  const handleOnChange = e => {
    const { title, value } = e.target;
    setSaleOrder({
      ...saleOrder,
      title: value,
    });
  };

  return (
    <>
      <Card className={"mb-2"}>
        <CardHeader className={"bg-transparent border-bottom"}>
          <Row>
            <Col>
              <CardTitle>Info</CardTitle>
            </Col>
            <Col className={"text-end"}>
              {saleOrder.id !== NIL ? (
                <UncontrolledButtonDropdown>
                  <DropdownToggle caret color="primary" outline>
                    <i className="mdi mdi-dots-vertical"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      onClick={() => setModalPrintPreview(true)}
                      className="text-primary"
                    >
                      <i className="mdi mdi-printer me-1" /> Print Preview
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledButtonDropdown>
              ) : null}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md="3">
              <FormGroup>
                <Label htmlFor="">Type</Label>
                <Select
                  name="type"
                  value={saleOrder.type}
                  onChange={handleSelectChange}
                  options={saleOrderTypOptions}
                  classNamePrefix="select2-selection"
                  isClearable
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-3">
                <Label htmlFor="">Order No</Label>
                <Input
                  type="text"
                  name="orderNo"
                  placeholder="Auto Generate"
                  value={saleOrder.orderNo}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="">Customer</Label>
                <CreatableUserSelect
                  modalTitle={"Create new customer"}
                  placeholder={"Select customer"}
                  name="customerId"
                  showPhoneNumber
                  roleNames={["Customer"]}
                  value={saleOrder.customerId}
                  onChange={handleSelectChange}
                />
              </FormGroup>
              <div
                className={
                  isSubmitted && !saleOrder.customerId ? "is-invalid" : ""
                }
              ></div>

              {isSubmitted && !saleOrder.customerId && (
                <div className="invalid-feedback-custom">
                  Customer is required.
                </div>
              )}
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="">Discount Promotion</Label>
                <Select
                  name="discountPromotionId"
                  value={saleOrder.discountPromotionId}
                  onChange={handleSelectChange}
                  options={discountOptions}
                  classNamePrefix="select2-selection"
                  isLoading={loadingSender}
                  isClearable
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label htmlFor="">Seller</Label>
                <UserSelect
                  name="sellerId"
                  value={saleOrder.sellerId}
                  roleNames={[
                    "Seller",
                    "System Administrator",
                    "Administrator",
                  ]}
                  onChange={handleSelectChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className={"mb-2"}>
        <CardHeader className={"bg-transparent border-bottom"}>
          <Row>
            <Col md={2}>
              <CardTitle className={"pt-2"}>Items</CardTitle>
            </Col>
            <Col className={"text-end"}></Col>
          </Row>
        </CardHeader>
        <CardHeader className={"bg-transparent border-bottom"}>
          <Row>
            <Col>
              <Label className={"mt-2"}>Find Items:</Label>
            </Col>
            <Col md={8}>
              <Select
                name="productId"
                value={null}
                placeholder={"Find by Code, Name, ..."}
                onChange={handleSelectChange}
                options={productOptions}
                components={{
                  SingleValue: customSingleValue,
                }}
                onInputChange={handleInputChange}
                formatOptionLabel={formatItemOptionLabel}
                classNamePrefix="select2-selection"
                isLoading={loading}
                isClearable
              />
            </Col>
            <Col>
              <div className="text-sm-end">
                <Button
                  color={"danger"}
                  onClick={handleDeleteSelected}
                  outline
                  disabled={
                    !saleOrder.saleOrderProducts.some(e => e.isSelected)
                  }
                >
                  <i className="fas fa-trash me-1" /> Delete Selected
                </Button>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table
            id="tech-companies-1"
            className="table-editable table table-striped table-bordered table-nowrap"
          >
            <thead className={"bg-primary text-white"}>
              <tr>
                <th className={"text-center"} style={{ width: "80px" }}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="headerSelected"
                    checked={isHeaderSelected}
                    onChange={handleHeaderSelect}
                  />
                </th>
                <th className={"text-center"} style={{ width: "120px" }}>
                  Image
                </th>
                <th className="text-center">Item</th>
                <th className={"text-center"} style={{ width: "250px" }}>
                  Price
                </th>
                <th className={"text-center"} style={{ width: "250px" }}>
                  Quantity
                </th>
                <th className={"text-center"} style={{ width: "250px" }}>
                  Uom
                </th>
                <th className={"text-center"} style={{ width: "250px" }}>
                  Discount
                </th>
                <th className={"text-center"} style={{ width: "250px" }}>
                  Total
                </th>
                <th className={"text-center"} style={{ width: "120px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {saleOrder.saleOrderProducts.map((entry, index) => {
                return (
                  <SaleOrderProductRow
                    key={index}
                    item={entry}
                    isSubmitted={isSubmitted}
                    onChange={handleStockInItemChange}
                    onDelete={handleDeleteStockInItem}
                  />
                );
              })}
            </tbody>
          </Table>
          <div
            className={
              isSubmitted && saleOrder.saleOrderProducts.length === 0
                ? "is-invalid"
                : ""
            }
          ></div>
          {isSubmitted && saleOrder.saleOrderProducts.length === 0 && (
            <div className="invalid-feedback-custom">There are no products</div>
          )}
        </CardBody>
      </Card>

        <Card className="mb-2">
          <CardHeader className="pb-1 bg-transparent border-bottom">
            <CardTitle>Summary</CardTitle>
          </CardHeader>
          <CardBody>
            <table className="table-nowrap table table-bordered" >
              <tbody>
                <tr>
                  <td className="border-0 text-end" >Total Amount</td>
                  <td className="text-sm-end" style={{ width: '180px' }}>
                    <NumberFormat
                      name="total"
                      value={saleOrder.saleOrderProducts.reduce((sum, item) => sum + (item.total || 0), 0).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-end">Discount</td>
                  <td className="text-end">
                    <NumberFormat
                      value={(saleOrder.discountPromotionId?.discount || 0).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="border-0 text-end"><strong>Total</strong></td>
                  <td className="text-end">
                    <NumberFormat
                      value={(saleOrder.saleOrderProducts.reduce((sum, item) => sum + (item.total || 0), 0) - (saleOrder.discountPromotionId?.discount || 0)).toFixed(2)}
                      displayType="text"
                      thousandSeparator={true}
                      fixedDecimalScale={true}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
        </Card>
        
        <Card className={ "mb-2" }>
          <CardBody>

            <Button color="primary" outline className={ "me-1" }
                    onClick={ () => handleSubmit(0) }
                    type="submit">
              Save Draft
            </Button>
            <Button color="primary" className={ "me-1" }
                    onClick={ () => handleSubmit(5) } // Generate Stock Out
                    type="submit">
              Submit
            </Button>

          {/*<Button color="primary" className={ "me-1" }*/}
          {/*        onClick={ handleSubmit }*/}
          {/*        type="submit">*/}
          {/*  Generate Stock Out*/}
          {/*</Button>*/}

          <Button color="secondary" onClick={() => onCancel()} type="button">
            Cancel
          </Button>
        </CardBody>
      </Card>
      {result && (
        <SaleOrderGenerateStockOutResultModal
          title={"Result Validation"}
          isOpen={modalResult}
          result={result}
          toggle={() => setModalResult(false)}
        />
      )}
      <ModalForm
        isOpen={modalAddSaleOrderProduct}
        toggle={() => setModalAddSaleOrderProduct(false)}
        title={"Select Product"}
        onSelected={handleOnSelected}
      />
    </>
  );
};

SaleOrderForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  item: PropTypes.object,
};

export default SaleOrderForm;
