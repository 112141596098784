import CustomPagination from "components/Common/CustomPagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
    getPagingSaleOrder,
    addSaleOrder,
    deleteSaleOrder
} from "store/actions";
import {
  Button,
  Card,
  CardBody,
  CardHeader, CardSubtitle, CardTitle,
  Col,
  Input,
  Row,
  Table,
} from "reactstrap";
import ConfirmDeleteModal from "components/Common/ConfirmDeleteModal";
import SaleOrderSelectionModal from "containers/sale-order/SaleOrderSelectionModal";
import { debounce, fromPairs } from "lodash";

const AppliedToSaleOrderTab = props => {
  const [ selectedId, setSelectedId ] = useState(null);
  const [ page, setPage ] = useState(1)
  const [ pageSize, setPageSize ] = useState(10);
  const [ term, setTerm ] = useState('');
  
  const [ modalAddIsOpen, setModalAddIsOpen ] = useState(false);
  const [ modalConfirmDeleteIsOpen, setModalConfirmDeleteIsOpen ] =
      useState(false);

  const debouncedFetchData = useCallback(debounce(term => {
    setTerm(term)
  }, 500), [])
  
  const {
    getPagingSaleOrder,
    addSaleOrder,
    deleteSaleOrder,
    saleOrder,
    saleOrderId
  } = props;
  
  const { 
    itemCount, 
    pageCount, 
    items,
    loading
  } = saleOrder
  
  useEffect(() => {
    getPagingSaleOrder({ 
      saleOrderId, 
      page, 
      pageSize, 
      term 
    });
  }, [ page, pageSize, term ]);

  const handleAddSaleOrder = (saleOrder) => {
    addSaleOrder({
      data: {
        saleOrderId: saleOrder.id,
        saleOrderId,
      },
      queryParams: {
        saleOrderId,
        page: 1,
        pageSize,
        term
      },
    })

    setModalAddIsOpen(false);
  }

  const handleDelete = () => {
    deleteSaleOrder({
      data: selectedId,
      queryParams: {
        saleOrderId,
        page: 1,
        pageSize,
        term
      },
    });
    setModalConfirmDeleteIsOpen(false);
  };

  return (
      <div>
        <SaleOrderSelectionModal
            title={ "Select Sale Order" }
            isOpen={ modalAddIsOpen }
            toggle={ () => setModalAddIsOpen(false) }
            onSelected={ handleAddSaleOrder }
        />

        <ConfirmDeleteModal
            title="Confirm Delete"
            isOpen={ modalConfirmDeleteIsOpen }
            toggle={ () => setModalConfirmDeleteIsOpen(!modalConfirmDeleteIsOpen) }
            onSubmit={ handleDelete }
        />
      </div>
  );
};


AppliedToSaleOrderTab.propTypes = {
    getPagingSaleOrder: PropTypes.func,
    addSaleOrder: PropTypes.func,
    saleOrder: PropTypes.object,
};

const mapStateToProps = ({ saleOrder }) => {
  return {
    saleOrder
  }
};

export default withRouter(
    connect(mapStateToProps, {
        getPagingSaleOrder,
        addSaleOrder,
        deleteSaleOrder
    })(AppliedToSaleOrderTab)
);