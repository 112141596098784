// Sale Order
const order = {
  enable: "Order.Enable",
  enableOnMobile: "Order.EnableOnMobile",
  read: "Order.Read",
  write: "Order.Write",
  delete: "Order.Delete",
  export: "Order.Export",
  generateInvoice: "OrderGenerateInvoice.Enable",
  changeDeliveryStatus: "OrderChangeDeliveryStatus.Enable",
};

// Transport
const receiveOrder = {
  enable: "ReceiveOrder.Enable",
};

const cargo = {
  enable: "Cargo.Enable",
  read: "Cargo.Read",
  write: "Cargo.Write",
  delete: "Cargo.Delete",
};

const transport = {
  enable: "Transport.Enable",
  read: "Transport.Read",
  write: "Transport.Write",
  delete: "Transport.Delete",
};

const transportNote = {
  enable: "TransportNote.Enable",
  read: "TransportNote.Read",
  write: "TransportNote.Write",
  delete: "TransportNote.Delete",
};

// Delivery order
const pickup = {
  enable: "Pickup.Enable",
};

const deliveryOrder = {
  enable: "DeliveryOrder.Enable",
  read: "DeliveryOrder.Read",
  write: "DeliveryOrder.Write",
  delete: "DeliveryOrder.Delete",
  export: "DeliveryOrder.Export",
};

const delivery = {
  enable: "Delivery.Enable",
  read: "Delivery.Read",
  write: "Delivery.Write",
  delete: "Delivery.Delete",
  export: "Delivery.Export",
};

const deliveryAgency = {
  enable: "DeliveryAgency.Enable",
  read: "DeliveryAgency.Read",
  write: "DeliveryAgency.Write",
  delete: "DeliveryAgency.Delete",
};

const deliverer = {
  enable: "Deliverer.Enable",
  read: "Deliverer.Read",
  write: "Deliverer.Write",
  delete: "Deliverer.Delete",
};

const supplier = {
  enable: "Supplier.Enable",
  read: "Supplier.Read",
  write: "Supplier.Write",
  delete: "Supplier.Delete",
};

// Invoice
const invoice = {
  enable: "Invoice.Enable",
};

const invoiceNote = {
  enable: "InvoiceNote.Enable",
  read: "InvoiceNote.Read",
  write: "InvoiceNote.Write",
  delete: "InvoiceNote.Delete",
};

const invoicePayment = {
  enable: "InvoicePayment.Enable",
  read: "InvoicePayment.Read",
  write: "InvoicePayment.Write",
  delete: "InvoicePayment.Delete",
  export: "InvoicePayment.Export",
};

const paymentHistory = {
  enable: "PaymentHistory.Enable",
};

// Inventory
const unit = {
  enable: "Unit.Enable",
  read: "Unit.Read",
  write: "Unit.Write",
  delete: "Unit.Delete",
};

const warehouse = {
  enable: "Warehouse.Enable",
  read: "Warehouse.Read",
  write: "Warehouse.Write",
  delete: "Warehouse.Delete",
};

const category = {
  enable: "Category.Enable",
  read: "Category.Read",
  write: "Category.Write",
  delete: "Category.Delete",
};

const attribute = {
  enable: "Attribute.Enable",
};

const banner = {
  enable: "Banner.Enable",
  read: "Banner.Read",
  write: "Banner.Write",
  delete: "Banner.Delete",
};

// User Management
const user = {
  enable: "User.Enable",
};

const role = {
  enable: "Role.Enable",
};

const permissionEntry = {
  enable: "Permission.Enable",
};

// Reports
const orderReport = {
  enable: "OrderReport.Enable",
};

const orderVerifyTracking = {
  enable: "OrderVerifyTracking.Enable",
};

const sendOrderReport = {
  enable: "SendOrderReport.Enable",
};

const receiveOrderReport = {
  enable: "ReceiveOrderReport.Enable",
};

const deliveryOrderReport = {
  enable: "DeliveryOrderReport.Enable",
};

const invoiceReport = {
  enable: "InvoiceReport.Enable",
};

// Settings
const setting = {
  enable: "Setting.Enable",
};

const currency = {
  enable: "Currency.Enable",
  read: "Currency.Read",
  write: "Currency.Write",
  delete: "Currency.Delete",
};

const shippingFee = {
  enable: "ShippingFee.Enable",
};

const deviceInfo = {
  enable: "DeviceInfo.Enable",
};

const feedback = {
  enable: "Feedback.Enable",
};

const alertNotification = {
  enable: "AlertNotification.Enable",
  read: "AlertNotification.Read",
  write: "AlertNotification.Write",
  delete: "AlertNotification.Delete",
};

const myDashboard = {
  enable: "MyDashboard.Enable",
};

const dashboard = {
  enable: "Dashboard.Enable",
};

const myOrder = {
  enable: "MyOrder.Enable",
};

const mySaleOrder = {
  enable: "MySaleOrder.Enable",
};

const mySaleOrderInvoice = {
  enable: "MySaleOrderInvoice.Enable",
};

const myInvoice = {
  enable: "MyInvoice.Enable",
};

const orderNote = {
  enable: "OrderNote.Enable",
  read: "OrderNote.Read",
  write: "OrderNote.Write",
  delete: "OrderNote.Delete",
};

const orderAttachment = {
  enable: "OrderAttachment.Enable",
  write: "OrderAttachment.Write",
  delete: "OrderAttachment.Delete",
};

const saleOrder = {
  enable: "SaleOrder.Enable",
  read: "SaleOrder.Read",
  write: "SaleOrder.Write",
  delete: "SaleOrder.Delete",
  export: "SaleOrder.Export",
};

const product = {
  enable: "Product.Enable",
  read: "Product.Read",
  write: "Product.Write",
  delete: "Product.Delete",
};

const tag = {
  enable: "Tag.Enable",
  read: "Tag.Read",
  write: "Tag.Write",
  delete: "Tag.Delete",
};

const uom = {
  enable: "Uom.Enable",
  read: "Uom.Read",
  write: "Uom.Write",
  delete: "Uom.Delete",
};

const vehicle = {
  enable: "Vehicle.Enable",
  read: "Vehicle.Read",
  write: "Vehicle.Write",
  delete: "Vehicle.Delete",
};

const stockIn = {
  enable: "StockIn.Enable",
  read: "StockIn.Read",
  write: "StockIn.Write",
  delete: "StockIn.Delete",
  export: "StockIn.Export",
};

const stockOut = {
  enable: "StockOut.Enable",
  read: "StockOut.Read",
  write: "StockOut.Write",
  delete: "StockOut.Delete",
  export: "StockOut.Export",
};

const stockAdjustment = {
  enable: "StockAdjustment.Enable",
  read: "StockAdjustment.Read",
  write: "StockAdjustment.Write",
  delete: "StockAdjustment.Delete",
  export: "StockAdjustment.Export",
};

const productTransaction = {
  enable: "productTransaction.Enable",
  read: "productTransaction.Read",
  write: "productTransaction.Write",
  delete: "productTransaction.Delete",
  export: "productTransaction.Export",
};

const saleOrderInvoice = {
  enable: "SaleOrderInvoice.Enable",
  read: "SaleOrderInvoice.Read",
  write: "SaleOrderInvoice.Write",
  delete: "SaleOrderInvoice.Delete",
};

const discountPromotion = {
  enable: "DiscountPromotion.Enable",
  read: "DiscountPromotion.Read",
  write: "DiscountPromotion.Write",
  delete: "DiscountPromotion.Delete",
};

export const permission = {
  order,
  orderNote,
  orderAttachment,

  transport,
  transportNote,
  receiveOrder,
  cargo,

  pickup,
  deliveryOrder,
  delivery,
  deliveryAgency,
  deliverer,
  supplier,

  invoice,
  invoiceNote,
  invoicePayment,
  paymentHistory,

  unit,
  warehouse,
  category,
  attribute,
  banner,

  user,
  role,
  permissionEntry,

  orderReport,
  orderVerifyTracking,
  sendOrderReport,
  receiveOrderReport,
  deliveryOrderReport,
  invoiceReport,

  setting,
  currency,
  shippingFee,
  deviceInfo,
  feedback,
  alertNotification,
  dashboard,
  myDashboard,
  myOrder,
  mySaleOrder,
  mySaleOrderInvoice,
  myInvoice,
  
  product,
  tag,
  uom,
  vehicle,
  
  stockIn,
  stockOut,
  stockAdjustment,
  productTransaction,
  
  discountPromotion,
  saleOrder,
  saleOrderInvoice
};
