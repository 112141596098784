import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  CardImg,
  CardTitle,
  Col,
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown
} from "reactstrap";
import {
  displayDeliveryPaymentStatus, displayDeliveryStatus,
  displayPaymentStatus,
  displayPickupPaymentStatus, displayVerifyStatus, getDeliveryStatusColor, getDeliveryStatusColorCode, getExpiryDateColorClass, getExpiryDateColorBorder,
  hasAccess
} from "../../helpers/utils";
import { permission } from "../../constants/permission";
import moment from "moment";
import NumberFormat from "react-number-format";
import { sumBy } from 'lodash'; // Ensure lodash is installed

const ProductRowCard = ({ onEdit, onConfirmDelete, onDownloadPrintLabel, onPrintPreview, onAddNote, onSendInvoice, onDeliveryStatusChange, onAddToTransport, ...props }) => {
  const { item, typeShow } = props;
  const [isExpand, setIsExpand] = useState(false);

  const { productImages, orderDocuments, lastNote } = item;
  const productImage = productImages && productImages.length > 0 ? productImages[0] : null;
  const colorClass = getExpiryDateColorClass(item.expiryDate);

  const calculateDateDifference = (expiryDate) => {
    const now = moment(); 
    const expiration = moment(expiryDate); 
    const totalMonths = expiration.diff(now, 'months', true); 
    const fullMonths = Math.abs(Math.floor(totalMonths)); 
    const formattedExpiryDate = expiration.format('DD-MMM-YYYY');

    if (fullMonths > 0) {
      return `${formattedExpiryDate} (${fullMonths} month${fullMonths > 1 ? 's' : ''})`;
    } else if (fullMonths === 0) {
      return `${formattedExpiryDate} (Due this month)`;
    } else {
      return `${formattedExpiryDate} (${Math.abs(fullMonths)} month${Math.abs(fullMonths) > 1 ? 's' : ''})`;
    }
  };
  

  return (
    <Card className={`mb-2 border ${getExpiryDateColorBorder(item.expiryDate)}`}>
      <CardHeader className={"bg-transparent border-bottom p-0 d-flex"}>
        <div>
          {productImage ? (
            <CardImg
              style={{ width: "250px", height: "200px", objectFit: "cover" }}
              src={productImage.url}
              alt="Product"
            />
          ) : (
            <div
              style={{
                width: "250px",
                height: "200px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="avatar-order">
                <i className="fas fa-solid fa-cube text-primary"></i>
              </div>
            </div>
          )}
        </div>
        <div className={"flex-grow-1"}>
          <CardHeader className={`text-white ${colorClass}`}>
            <Row onDoubleClick={() => typeShow !== "user" && onEdit(item.id)} style={{cursor:'pointer'}}>
              <Col md={4}>
                <CardTitle>{item.name}</CardTitle>
              </Col>
            </Row>
            {typeShow !== "user" && <div
                style={{
                  position: "absolute",
                  top: "6px",
                  right: "10px",
                  cursor: "pointer",
                  display: "flex"
                }}
            >
              <UncontrolledDropdown className={ "px-2" }>
                <DropdownToggle className="card-drop text-white" tag="a">
                  <i className="mdi mdi-dots-horizontal font-size-24"/>
                </DropdownToggle>
                <DropdownMenu
                    end={true}
                    className="text-primary"
                >
                  {hasAccess(permission.order.read) && (
                      <DropdownItem onClick={() => onEdit(item.id)}>
                        <i className="mdi mdi-pencil font-size-16 text-success me-1" />{" "}
                        Edit
                      </DropdownItem>
                  )}
                  {hasAccess(permission.order.delete) && (
                      <DropdownItem
                          onClick={() => onConfirmDelete(item.id)}
                      >
                        <i className="mdi mdi-trash-can font-size-16 text-danger me-1" />{" "}
                        Delete
                      </DropdownItem>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
              <span className="font-size-24 text-white ps-2 pe-2" onClick={()=> setIsExpand(!isExpand)}>
                <i className={
                      isExpand
                          ? "fas fa-angle-up"
                          : "fas fa-angle-down"
                    }
                ></i>
              </span>
            </div>
          }
          </CardHeader>
          <div style={{ padding: "1.25rem" }}>
            <Row>
              <Col>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <h5 className="font-size-14 mb-1">{item.sender} {item.senderRoles?.map((role, index) => (
                    <Badge key={index} color={"primary"} className={"mx-1 pt-1 px-2"}>{role.name}</Badge>
                  ))}</h5>
                  <h5>Info</h5>
                  <p>Barcode: {item.barcode}</p>
                  <p className="d-flex align-items-center">
                    Publish: 
                    <span className={item.published ? 'text-success ms-2' : 'text-danger ms-2'}>
                        <i className={`font-size-18 fa ${item.published ? 'fa-check' : 'fa-times'}`}/>
                    </span>
                  </p>
                  <p className="d-flex align-items-center">
                    Is Feature: 
                    <span className={item.isFeature ? 'text-success ms-2' : 'text-danger ms-2'}>
                      <i className={`font-size-18 fa ${item.isFeature ? 'fa-check' : 'fa-times'}`} />
                    </span>
                  </p>
                 
                  <p>{calculateDateDifference(item.expiryDate)}</p>

                </div>
              </Col>
              <Col>
                <h5>Price Information</h5>
                <p>
                  Cost:{" "}
                  <NumberFormat
                    value={item.cost || 0}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                  />
                </p>
                <p>
                  Price:{" "}
                  <NumberFormat
                    value={item.price || 0}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                  />
                </p>
                <p>
                  Old Price:{" "}
                  <NumberFormat
                    value={item.oldPrice || 0}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="$"
                  />
                </p>
              </Col>
              <Col>
                <h5>Stock Information</h5>
                <p>
                  Total Unit:{" "}
                  <NumberFormat
                    value={sumBy(item.productUoms || [], a => a.onHand * a.conversionRate) || 0}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </p>
                <p>
                  Total Box:{" "}
                  <NumberFormat
                    value={sumBy(item.productUoms || [], a => a.boxCount || 0) || 0}
                    displayType="text"
                    thousandSeparator={true}
                  />
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </CardHeader>
      <Collapse isOpen={isExpand}>
        <CardBody>
          {lastNote &&
              <>
                <CardTitle>Note</CardTitle>
                <p className="text-muted">{lastNote?.note}</p>
                <CardTitle>Created Date</CardTitle>
                <p className="text-muted">
                  {moment
                      .utc(lastNote?.createdDate)
                      .local()
                      .format("DD-MMM-yyyy h:mm:ss a")}
                </p>
                <CardTitle>Created By</CardTitle>
                <p className="text-muted">{lastNote?.createdByUser}</p>  
              </>
          }
        </CardBody>
      </Collapse>
    </Card>
  );
};

ProductRowCard.propTypes = {
  onEdit: PropTypes.func.isRequired,
  onConfirmDelete: PropTypes.func.isRequired,
  onDownloadPrintLabel: PropTypes.func.isRequired,
  onPrintPreview: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
  onSendInvoice: PropTypes.func.isRequired,
  onDeliveryStatusChange: PropTypes.func.isRequired,
  onAddToTransport: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  typeShow: PropTypes.string.isRequired,
};

export default ProductRowCard;
