import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { debounce } from "lodash";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Table
} from "reactstrap";
import CustomPagination from "components/Common/CustomPagination";
import {
  getDeliveries,
} from "store/actions";
import { displayDeliveryOrderStatus } from "helpers/utils";

const UserDeliveryTab = props => {
  const [term, setTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    getDeliveries,
    delivery,
    userId
  } = props;

  const {
    items,
    item,
    totalAmount,
    message,
    itemCount,
    pageCount,
    loading,
  } = delivery;

  const debouncedQuickSearch = useCallback(
    debounce(term => {
      setTerm(term);
      setPage(1);
    }, 1000),
    []
  );

  useEffect(() => {
    getDeliveries({ userId, term, page, pageSize });
  }, [ term, page, pageSize ]);

  return (
    <div>
      <Card className="mb-1">
        <CardHeader className="bg-transparent border-bottom">
          <Row className="g-1">
            <Col md={2}>
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative">
                  <Input
                    type="text"
                    placeholder="Quick Search ..."
                    onChange={e => debouncedQuickSearch(e.target.value)}
                  />
                  <i className="fas fa-search search-icon" />
                </div>
              </div>
            </Col>
          </Row>
        </CardHeader>
      </Card>

      <div className="table-rep-plugin">
        {loading ? (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-primary m-1" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <Card>
            <CardBody>
              <div className="table-responsive mb-0" data-pattern="priority-columns">
                <Table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>Agency</th>
                      <th>Status</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item, index) => {
                      const num = (page - 1) * pageSize + (index + 1);
                      return (
                        <tr key={index}>
                          <td>{num}</td>
                          <td>{item.title}</td>
                          <td>{item.deliveryAgency}</td>
                          <td>{displayDeliveryOrderStatus(item)}</td>
                          <td>{moment(item.date).format("DD-MMM-yyyy")}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        )}
      </div>

      <CustomPagination
        itemCount={itemCount}
        currentPage={page}
        totalPage={pageCount}
        defaultPageSize={pageSize}
        pageSizeOptions={[5, 10, 20, 30, 40, 50, 100]}
        onChangePage={setPage}
        onPageSizeChange={size => {
          setPage(1);
          setPageSize(size);
        }}
      />
    </div>
  );
};

UserDeliveryTab.propTypes = {
  userId: PropTypes.string,
  delivery: PropTypes.object,
  getDeliveries: PropTypes.func
};

const mapStateToProps = ({ delivery }) => {
  return { delivery };
};

export default withRouter(
  connect(mapStateToProps, {
    getDeliveries
  })(UserDeliveryTab)
);
