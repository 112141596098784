import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Form,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      title: "",
      transportDate: null,
      status: 0,
      submitted: false,
    };
  }

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
      });
    } else {
      this.setState({
        id: NIL,
        title: "",
        status: 0,
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSubmit = () => {
    const { id, title, transportDate, status } = this.state;
    this.setState({
      submitted: true,
    });

    let isValid = title && transportDate;

    if (isValid) {
      const transport = {
        id,
        title,
        transportDate,
        status: status.value,
      };

      this.props.onSubmit(transport);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted, transportDate } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="validationCustom01">Title</Label>
                <Input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className={submitted && !this.state.title ? "is-invalid" : ""}
                  value={this.state.title}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.title && (
                  <div className="invalid-feedback-custom">
                    Title is required.
                  </div>
                )}
              </FormGroup>
              <FormGroup
                className={
                  "select2-container" +
                  (submitted && !transportDate ? " is-invalid" : "")
                }
              >
                <Label>Transport Date</Label>
                <DatePicker
                  className="form-control"
                  selectsStart
                  name="transportDate"
                  selected={this.state.transportDate}
                  onChange={date =>
                    this.handleDateChange(date, "transportDate")
                  }
                  dateFormat="dd-MMM-yyyy"
                  placeholderText="Transport Date"
                  autoComplete="off"
                  isClearable
                />
              </FormGroup>
              {submitted && !transportDate && (
                <div className="invalid-feedback-custom">
                  Transport Date is required.
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
        {hasAccess(permission.transport.write) && (
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
        )}
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
