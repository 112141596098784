import React, { Component } from "react";
import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
} from "reactstrap";
import "toastr/build/toastr.min.css";
import Breadcrumbs from "components/Common/Breadcrumb";
import { NIL } from "uuid";
import classnames from "classnames";
import {
  getDeviceInfo,
  getInvoicePaymentHistory,
  getInvoices,
  getOrders,
  getUserById,
  getPagingUserDocument,
  getPagingUserVehicle,
  getDeliveries,
} from "store/actions";
import UserInfoTab from "./UserInfoTab";
import ShippingContactTab from "./ShippingContactTab";
import SendOrderTab from "./SendOrderTab";
import InvoiceTab from "./InvoiceTab";
import InvoicePaymentTab from "./InvoicePaymentTab";
import ReceiveOrderTab from "./ReceiveOrderTab";
import DeviceInfoTab from "./DeviceInfoTab";
import { getCompanyName } from "../../../helpers/utils";
import UserDocumentTab from "./UserDocumentTab";
import UserVehicleTab from "./UserVehicleTab";
import UserDeliveryTab from "./UserDeliveryTab";

export class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      activeTab: "1",
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  componentDidMount() {
    const { match } = this.props;
    const { id, activeTab } = match.params;

    if (id !== NIL) {
      this.setState({
        id,
        activeTab: activeTab ?? "1",
      });
      this.props.getUserById(id);
    }
  }

  populationUserInfo = () => {
    const { id } = this.state;
    this.props.getUserById(id);
  };

  handlePopulateSendOrder = () => {
    const { id } = this.state;
    this.props.getOrders({ senderId: id });
  };

  handlePopulateReceiveOrder = () => {
    const { id } = this.state;
    this.props.getOrders({ receiverId: id });
  };

  handlePopulateInvoice = () => {
    const { id } = this.state;
    this.props.getInvoices({ userId: id });
  };

  handlePopulateInvoicePayment = () => {
    const { id } = this.state;
    this.props.getInvoicePaymentHistory({ userId: id });
  };

  handlePopulateDeviceInfo = () => {
    const { id } = this.state;
    this.props.getDeviceInfo({ userId: id });
  };

  render() {
    const { getPagingUserDocument, getPagingUserVehicle, getDeliveries } = this.props;

    return (
        <React.Fragment>
          <div className="page-content">
            <MetaTags>
              <title>User Detail | { getCompanyName() }</title>
            </MetaTags>
            <Container fluid>
              <Breadcrumbs
                  title="User Management"
                  backLink={ "/user" }
                  breadcrumbItem="User Detail"
              />

              <Row>
                <Col lg={ 12 }>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "1",
                          }) }
                          onClick={ () => {
                            this.toggle("1");
                            this.populationUserInfo();
                          } }
                      >
                        Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "2",
                          }) }
                          onClick={ () => {
                            this.toggle("2");
                            this.handlePopulateSendOrder();
                          } }
                      >
                        Send Orders
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "3",
                          }) }
                          onClick={ () => {
                            this.toggle("3");
                            this.handlePopulateReceiveOrder();
                          } }
                      >
                        Receive Orders
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "4",
                          }) }
                          onClick={ () => {
                            this.toggle("4");
                            this.handlePopulateInvoice();
                          } }
                      >
                        Invoices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "5",
                          }) }
                          onClick={ () => {
                            this.toggle("5");
                            this.handlePopulateInvoicePayment();
                          } }
                      >
                        Invoice Payments
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "6",
                          }) }
                          onClick={ () => {
                            this.toggle("6");
                          } }
                      >
                        Shipping Contact
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "7",
                          }) }
                          onClick={ () => {
                            this.toggle("7");
                            this.handlePopulateDeviceInfo();
                          } }
                      >
                        Devices
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "8",
                          }) }
                          onClick={ () => {
                            this.toggle("8");
                            getPagingUserDocument({ userId: this.state.id })
                          } }
                      >
                        Documents
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "9",
                          }) }
                          onClick={ () => {
                            this.toggle("9");
                            getPagingUserVehicle({ userId: this.state.id })
                          } }
                      >
                        Vehicles
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                          style={ { cursor: "pointer" } }
                          className={ classnames({
                            active: this.state.activeTab === "10",
                          }) }
                          onClick={ () => {
                            this.toggle("10");
                            getDeliveries({ userId: this.state.id })
                          } }
                      >
                        Delivery
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent activeTab={ this.state.activeTab }>
                    <TabPane tabId="1">
                      { this.state.id !== NIL && (
                          <UserInfoTab id={ this.state.id }/>
                      ) }
                    </TabPane>

                    <TabPane tabId="2">
                      { this.state.id != NIL && (
                          <SendOrderTab userId={ this.state.id }/>
                      ) }
                    </TabPane>
                    <TabPane tabId="3">
                      { this.state.id != NIL && (
                          <ReceiveOrderTab userId={ this.state.id }/>
                      ) }
                    </TabPane>
                    <TabPane tabId="4">
                      { this.state.id != NIL && (
                          <InvoiceTab userId={ this.state.id }/>
                      ) }
                    </TabPane>
                    <TabPane tabId="5">
                      { this.state.id != NIL && (
                          <InvoicePaymentTab userId={ this.state.id }/>
                      ) }
                    </TabPane>

                    <TabPane tabId="6">
                      { this.state.id != NIL && (
                          <ShippingContactTab userId={ this.state.id }/>
                      ) }
                    </TabPane>
                    <TabPane tabId="7">
                      { this.state.id != NIL && (
                          <DeviceInfoTab userId={ this.state.id }/>
                      ) }
                    </TabPane>

                    <TabPane tabId="8">
                      { this.state.id != NIL && (
                          <UserDocumentTab userId={ this.state.id }/>
                      ) }
                    </TabPane>

                    <TabPane tabId="9">
                      { this.state.id != NIL && (
                          <UserVehicleTab userId={ this.state.id }/>
                      ) }
                    </TabPane>

                    <TabPane tabId="10">
                      { this.state.id != NIL && (
                          <UserDeliveryTab userId={ this.state.id }/>
                      ) }
                    </TabPane>
                  </TabContent>
                </Col>
              </Row>
            </Container>
          </div>
        </React.Fragment>
    );
  }
}

UserDetail.propTypes = {
  loading: PropTypes.bool,
  item: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  getUserById: PropTypes.func,
  getOrders: PropTypes.func,
  getInvoices: PropTypes.func,
  getInvoicePaymentHistory: PropTypes.func,
  getDeviceInfo: PropTypes.func,
  getPagingUserDocument: PropTypes.func,
  getPagingUserVehicle: PropTypes.func,
  getDeliveries: PropTypes.func,
};

const mapStateToProps = ({ user }) => {
  return user;
};

export default withRouter(
    connect(mapStateToProps, {
      getUserById,
      getOrders,
      getInvoices,
      getInvoicePaymentHistory,
      getDeviceInfo,
      getPagingUserDocument,
      getPagingUserVehicle,
      getDeliveries
    })(UserDetail)
);
