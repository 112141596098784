import React, { Component } from "react";
import PropTypes from "prop-types";
import { NIL } from "uuid";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  Label,
  Row,
  Input,
  Form,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { hasAccess } from "helpers/utils";
import { permission } from "constants/permission";
import "react-datepicker/dist/react-datepicker.css";

export class ModalForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: NIL,
      name: "",
      description: "",

      submitted: false,
    };
  }

  onOpened = () => {
    const { item } = this.props;

    if (item) {
      this.setState({
        ...item,
      });
    } else {
      this.setState({
        id: NIL,
        name: "",
        description: "",
        submitted: false,
      });
    }
  };

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleDateChange = (date, name) => this.setState({ [name]: date });

  handleSubmit = () => {
    const { id, name, description } = this.state;
    this.setState({
      submitted: true,
    });

    let isValid = name;

    if (isValid) {
      const item = {
        id,
        name,
        description,
      };

      this.props.onSubmit(item);
    }
  };

  render() {
    const { isOpen, toggle, title } = this.props;
    const { submitted } = this.state;
    return (
      <Modal
        size="md"
        onOpened={this.onOpened}
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {title}
          </h5>
          <button
            type="button"
            onClick={toggle}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div className="modal-body">
          <Row>
            <Col md={12}>
              <FormGroup className="mb-3">
                <Label htmlFor="">Name</Label>
                <Input
                  type="text"
                  name="name"
                  placeholder="Name"
                  className={submitted && !this.state.name ? "is-invalid" : ""}
                  value={this.state.name}
                  onChange={this.handleChange}
                />
                {submitted && !this.state.name && (
                  <div className="invalid-feedback-custom">
                    Name is required.
                  </div>
                )}
              </FormGroup>
              <FormGroup className="mb-3">
                <Label htmlFor="">Description</Label>
                <Input
                  type="textarea"
                  rows="3"
                  name="description"
                  placeholder="Title"
                  value={this.state.description}
                  onChange={this.handleChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          {hasAccess(permission.deliveryAgency.write) && (
          <Button color="primary" onClick={this.handleSubmit} type="submit">
            Submit
          </Button>
          )}
          <button
            type="button"
            onClick={toggle}
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

ModalForm.propTypes = {
  item: PropTypes.object,
  title: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalForm;
